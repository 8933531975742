import React from "react";
import { Button, Grid, IconButton, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { ArrowDownward, ArrowUpward, Edit } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import {
  getPDFForParagrafo,
  postAggiuntaPDFForParagrafo,
  postUploadImmaginiSottoparagrafi,
} from "../../../utils/api/paragrafi_api.js";

import TwoActionsAlertDialogForFileUpload from "../../../components/TwoActionsAlertDialogForFileUpload.js";
import PropTypes from "prop-types";
import AttachmentList from "../components/AttachmentList.js";

const styles = (theme) => ({
  mainContainer: {
    alignSelf: "center",
    textAlign: "center",
    color: "black",
    paddingBottom: "1%",
    display: "flex",
    flexDirection: "column",
    height: "50%",
    width: "100%",
  },
  header: {
    fontSize: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "50%",
    marginTop: "auto",
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "100%",
  },
  firstDivider: {
    border: "1px solid black",
    width: "100%",
    marginLeft: "0%",
  },
  inputWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
    margin: "8px 0",
  },
  button: {
    color: theme.palette.text.main,
    backgroundColor: theme.palette.primary.main,
    marginBottom: "1%",
    marginLeft: "0%",
    marginTop: "1%",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  secondDivider: {
    border: "1px solid black",
    width: "100%",
    marginBottom: "1%",
    marginLeft: "0%",
  },
});

class NewSottoparagrafoTestoEImmagine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titoloInizialeSottoparagrafo: props.titoloInizialeSottoparagrafo,
      testoSottoparagrafo: props.testoSottoParagrafo,
      currentFile: "",
      previewImage: "",
      isBlocked: props.isParagrafoBlocked,
      dialogVisible: false,
      idImmagine: props.idImmagine,
      sottoparagrafoData: props.sottoParagrafiData,
      isTitleEditable: false,
      sottoparagrafoIndex: this.props.indexValue,
      sottoparagrafoAttachments: props.immaginiParagrafo,
      imagesNames: [],
      images: [],
      newPdfFile: null,
      twoActionAlert: {
        isVisible: false,
        title: "",
        message: "",
        message2: "",
        loading: false,
      },
      uploadedPdfFile: null,
    };
  }

  // Funzione helper per verificare se l'utente può modificare
  canEdit = () => {
    const isAdmin = localStorage.getItem("consulenteId") === "null";
    const isSuperConsulente =
      localStorage.getItem("superconsulente") === "true";
    return !this.state.isBlocked || isAdmin || isSuperConsulente;
  };

  componentDidMount() {
    this.loadImages(this.state.sottoparagrafoAttachments);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sottoParagrafiData !== this.props.sottoParagrafiData) {
      this.setState(
        {
          titoloInizialeSottoparagrafo: this.props.titoloInizialeSottoparagrafo,
          testoSottoparagrafo: this.props.testoSottoParagrafo,
          idImmagine: this.props.idImmagine,
          sottoparagrafoData: this.props.sottoParagrafiData,
          sottoparagrafoIndex: this.props.indexValue,
          sottoparagrafoAttachments: this.props.immaginiParagrafo,
          isBlocked: this.props.isParagrafoBlocked,
          images: [],
          imagesNames: [],
        },
        () => {
          this.loadImages(this.state.sottoparagrafoAttachments);
        }
      );
    }
    if (
      prevProps.sottoParagrafiData === this.props.sottoParagrafiData &&
      this.state.sottoparagrafoIndex !== this.props.indexValue
    ) {
      this.setState(
        {
          titoloInizialeSottoparagrafo: this.props.titoloInizialeSottoparagrafo,
          testoSottoparagrafo: this.props.testoSottoParagrafo,
          idImmagine: this.props.idImmagine,
          sottoparagrafoData: this.props.sottoParagrafiData,
          sottoparagrafoIndex: this.props.indexValue,
          sottoparagrafoAttachments: this.props.immaginiParagrafo,
          isBlocked: this.props.isParagrafoBlocked,
        },
        () => {
          this.loadImages(this.state.sottoparagrafoAttachments);
        }
      );
    }
  }

  loadImages = (arrayOfImagesId) => {
    arrayOfImagesId.forEach((item, index) => (item.posizione = index));
  };

  bringImageToParent = (imageList, index, changer) => {
    const selectedImage = imageList[imageList.length - 1];

    if (!imageList || imageList.length === 0 || !selectedImage) {
      return;
    }
    if (selectedImage.type !== "image/png") {
      this.props.handleError(
        true,
        "Attenzione, possono essere caricate solo immagini in formato PNG."
      );
    } else if (selectedImage.size > 500000) {
      this.props.handleError(
        true,
        "Attenzione, la dimensione dell'immagine caricata è maggiore 500kB."
      );
    } else {
      postUploadImmaginiSottoparagrafi(selectedImage)
        .then((result) => {
          let newSubparagraphArray = [...this.state.sottoparagrafoData];
          let newIdsArray = [...this.state.sottoparagrafoAttachments];

          const newImageAttachment = {
            id: result,
            posizione: newIdsArray.length,
            note: "",
            titolo: "",
          };

          let elementToChange = newSubparagraphArray.filter(
            (item) => item.numeroComponente === index
          );

          elementToChange[0].immagini.push(newImageAttachment);
          newIdsArray.push(newImageAttachment);

          this.setState(
            (prevState) => ({
              sottoparagrafoData: newSubparagraphArray,
              sottoparagrafoAttachments: newIdsArray,
              currentFile: changer,
              previewImage: URL.createObjectURL(changer),
              images: [...prevState.images, URL.createObjectURL(changer)],
              imagesNames: [...prevState.imagesNames, changer.name],
            }),
            () => {
              this.props.handleEditChange();
            }
          );
        })
        .catch((error) => {
          if (error.status === 403) {
            this.props.handleInvalidToken();
          } else {
            this.props.handleError(true, error.message);
          }
        });
    }
  };

  swapElements = (arr, i1, i2) => {
    let temp = arr[i1];

    arr[i1] = arr[i2];

    arr[i2] = temp;
  };

  swapImmagini = (arr, i1, i2) => {
    let temp = arr[i1];
    let tempPosizione1 = arr[i1].posizione;
    let tempPosizione2 = arr[i2].posizione;
    arr[i1] = arr[i2];

    arr[i2] = temp;
    arr[i1].posizione = tempPosizione1;
    arr[i2].posizione = tempPosizione2;
  };

  rimuoviAllegato = (id) => {
    if (!this.canEdit()) {
      return alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }

    const { sottoparagrafoAttachments } = this.state;
    const updatedAttachments = sottoparagrafoAttachments.filter(
      (attachment) => attachment.id !== id
    );

    if (updatedAttachments.length === sottoparagrafoAttachments.length) {
      return; // Se l'immagine non è stata trovata, esci
    }

    this.setState(
      {
        sottoparagrafoAttachments: updatedAttachments,
      },
      this.props.handleEditChange
    );

    this.props.deleteImage(updatedAttachments, this.props.indexValue);
  };

  moveAttachment = (index, indexToMove) => {
    if (this.canEdit()) {
      let arrayOfImages = [...this.state.sottoparagrafoAttachments];
      let arrayOfData = [...this.state.sottoparagrafoData];
      this.swapElements(
        arrayOfData[this.state.sottoparagrafoIndex].immagini,
        index,
        indexToMove
      );
      this.swapImmagini(arrayOfImages, index, indexToMove);
      this.setState(
        {
          sottoparagrafoAttachments: arrayOfImages,
          sottoparagrafoData: arrayOfData,
        },
        () => {
          this.props.handleEditChange();
        }
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  changeField = (event, index, field) => {
    if (this.canEdit()) {
      let arrayOfImages = [...this.state.sottoparagrafoAttachments];
      let arrayOfData = [...this.state.sottoparagrafoData];
      arrayOfData[this.state.sottoparagrafoIndex].immagini[index][field] =
        event;
      arrayOfImages[index][field] = event;
      this.setState(
        {
          images: arrayOfImages,
          sottoparagrafoData: arrayOfData,
        },
        () => {
          this.props.handleEditChange();
        }
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  handleEditIconClick = () => {
    if (this.canEdit()) {
      this.setState((prevState) => ({
        isTitleEditable: !prevState.isTitleEditable,
      }));
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  handleImageFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (this.canEdit()) {
      let arrayOfImagesToPass = [...this.state.images, selectedFile];
      this.bringImageToParent(
        arrayOfImagesToPass,
        this.props.indexValue,
        selectedFile
      );
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato"
      );
    }
  };

  handlePdfFileChange = (event) => {
    if (this.canEdit()) {
      const pdfFile = event.target.files[0];

      if (!pdfFile) return;

      if (pdfFile.type !== "application/pdf") {
        this.props.handleError(
          true,
          "Attenzione, possono essere caricati solo documenti in formato PDF."
        );
        return;
      }

      if (pdfFile.size > 1000000) {
        this.props.handleError(
          true,
          "Attenzione, la dimensione del documento caricato è maggiore di 1MB."
        );
        return;
      }

      this.setState({
        newPdfFile: pdfFile,
        twoActionAlert: {
          isVisible: true,
          title: "Conferma caricamento PDF",
          message:
            "Il documento inserito non sarà editabile e avrà una numerazione interna. La dimensione massima consentita è 1MB.",
          message2: (
            <>
              Nome file: {pdfFile.name} <br /> Dimensione file:{" "}
              {Math.floor(pdfFile.size / 1000)} kB
            </>
          ),
          loading: false,
        },
      });
    } else {
      alert(
        "Attenzione, operazione non permessa poichè il paragrafo è bloccato."
      );
    }
  };

  cancelPdfUpload = () => {
    this.setState({
      newPdfFile: null,
      twoActionAlert: {
        isVisible: false,
        title: "",
        message: "",
        message2: "",
        loading: false,
      },
    });
  };

  uploadPdfFile = (index) => {
    const { newPdfFile } = this.state;
    let updatedSubparagraphArray = [...this.state.sottoparagrafoData];
    let updatedAttachments = [...this.state.sottoparagrafoAttachments];
    let componentToUpdate = updatedSubparagraphArray.filter(
      (item) => item.numeroComponente === index
    );

    if (!newPdfFile) {
      return;
    }

    this.setState((prevState) => ({
      twoActionAlert: {
        ...prevState.twoActionAlert,
        loading: true,
      },
    }));

    const formData = new FormData();
    formData.append("file", newPdfFile);
    postAggiuntaPDFForParagrafo(formData)
      .then((response) => {
        const newPdfAttachment = {
          id: response.id,
          note: "",
          posizione: updatedAttachments.length,
          titolo: newPdfFile.name,
          type: "PDF",
        };

        componentToUpdate[0].immagini.push(newPdfAttachment);
        updatedAttachments.push(newPdfAttachment);

        this.setState(
          {
            sottoparagrafoData: updatedSubparagraphArray,
            sottoparagrafoAttachments: updatedAttachments,
            newPdfFile: null,
            twoActionAlert: {
              isVisible: false,
              title: "",
              message: "",
              message2: "",
              loading: false,
            },
          },
          this.props.handleEditChange
        );
      })
      .catch((error) => {
        this.setState((prevState) => ({
          ...prevState.twoActionAlert,
          loading: false,
        }));
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  handlePdfView = (pdfId) => {
    if (!pdfId) {
      this.props.handleError(
        true,
        "ID PDF mancante. Non è possibile recuperare il documento."
      );
      return;
    }

    getPDFForParagrafo(pdfId)
      .then((response) => {
        if (response?.url) {
          window.open(response.url, "_blank");
        } else {
          this.props.handleError(true, "URL PDF non disponibile.");
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.props.handleInvalidToken();
        } else {
          this.props.handleError(true, error.message);
        }
      });
  };

  render() {
    const { classes } = this.props;
    const { twoActionAlert } = this.state;
    const canEdit = this.canEdit();

    return (
      <div className={classes.mainContainer}>
        <div className={classes.header}>
          <Grid>
            <TextField
              InputProps={{
                disableUnderline: true,
                style: { fontSize: 30, width: "18em" },
              }}
              multiline
              InputLabelProps={{ style: { fontSize: 30 } }}
              disabled={!this.state.isTitleEditable}
              onInput={(e) => {
                this.setState({
                  titoloInizialeSottoparagrafo: e.target.value,
                });
              }}
              onBlur={() => {
                this.props.bringTitleToParent(
                  this.state.titoloInizialeSottoparagrafo,
                  this.props.indexValue
                );
              }}
              value={this.state.titoloInizialeSottoparagrafo}
            />
          </Grid>
          {!this.props.firstElement && (
            <div className={classes.iconWrapper}>
              <IconButton onClick={this.handleEditIconClick}>
                <Edit
                  htmlColor={
                    this.state.isTitleEditable
                      ? this.props.theme.palette.primary.main
                      : "#707070"
                  }
                />
              </IconButton>
              <IconButton
                onClick={() =>
                  this.props.rimuoviSottoparagrafo(this.props.indexValue)
                }
              >
                <CloseIcon />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => this.props.moveUpward(this.props.indexValue)}
              >
                <ArrowUpward />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => this.props.moveDownward(this.props.indexValue)}
              >
                <ArrowDownward />
              </IconButton>
            </div>
          )}
        </div>
        <div className={classes.textBox}>
          <TextField
            label="Inserire il testo..."
            variant="outlined"
            inputProps={{ disableunderline: "true" }}
            value={this.state.testoSottoparagrafo}
            name="contenuto"
            onChange={(e) => {
              this.setState({
                testoSottoparagrafo: e.currentTarget.value,
              });
            }}
            disabled={!canEdit}
            onBlur={() => {
              this.props.bringDataToParent(
                this.state.testoSottoparagrafo,
                this.props.indexValue
              );
            }}
            multiline
            rows={20}
            fullWidth
            style={styles.textfield}
          />
        </div>
        <div className={classes.firstDivider} />

        {this.state.sottoparagrafoAttachments.length !== 0 && (
          <AttachmentList
            attachments={this.state.sottoparagrafoAttachments}
            onRemoveAttachment={this.rimuoviAllegato}
            onMove={this.moveAttachment}
            onChangeField={this.changeField}
            urlPath="/api/paragrafi/sottoparagrafi/immagini/"
            onViewPdf={this.handlePdfView}
          />
        )}
        <div className={classes.inputWrapper}>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={this.handleImageFileChange}
            id={`image-input-${this.props.idImmagine}`}
          />
          <label htmlFor={`image-input-${this.props.idImmagine}`}>
            <Button
              variant="contained"
              className={classes.button}
              component="span"
            >
              Aggiungi immagine PNG
            </Button>
          </label>
          <input
            type="file"
            accept="application/pdf"
            style={{ display: "none" }}
            onChange={this.handlePdfFileChange}
            id={`pdf-input-${this.props.idImmagine}`}
          />
          <label htmlFor={`pdf-input-${this.props.idImmagine}`}>
            <Button
              variant="contained"
              className={classes.button}
              component="span"
            >
              Aggiungi documento PDF
            </Button>
          </label>
        </div>
        <div className={classes.secondDivider} />
        <TwoActionsAlertDialogForFileUpload
          visible={twoActionAlert.isVisible}
          title={twoActionAlert.title}
          message={twoActionAlert.message}
          message2={twoActionAlert.message2}
          onIndietroClicked={this.cancelPdfUpload}
          onOkClicked={() => this.uploadPdfFile(this.state.sottoparagrafoIndex)}
          loading={twoActionAlert.loading}
        />
      </div>
    );
  }
}

NewSottoparagrafoTestoEImmagine.propTypes = {
  bringDataToParent: PropTypes.func.isRequired,
  bringTitleToParent: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  deleteImage: PropTypes.func.isRequired,
  firstElement: PropTypes.bool.isRequired,
  handleEditChange: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  handleInvalidToken: PropTypes.func.isRequired,
  idImmagine: PropTypes.number.isRequired,
  immaginiParagrafo: PropTypes.array,
  indexValue: PropTypes.number.isRequired,
  isParagrafoBlocked: PropTypes.bool.isRequired,
  moveDownward: PropTypes.func,
  moveUpward: PropTypes.func,
  rimuoviSottoparagrafo: PropTypes.func.isRequired,
  sottoParagrafiData: PropTypes.array,
  testoSottoParagrafo: PropTypes.string,
  theme: PropTypes.object.isRequired,
  titoloInizialeSottoparagrafo: PropTypes.string,
};

export default withStyles(styles)(NewSottoparagrafoTestoEImmagine);
